import { mbHidePopUp, mbShowPopUp } from '@mightybyte/rnw.components.pop-up';
import { MB_ToastShowParams } from '@mightybyte/rnw.components.toast';
import { MB_customWindowForWeb } from '@mightybyte/rnw.utils.custom-window-for-web';
import { isMobileApp } from '@mightybyte/rnw.utils.device-info';
import * as WebBrowser from 'expo-web-browser';
import React, { ComponentProps, FC } from 'react';
import { envs } from '../../env';
import { serverRedirectUtils } from '../components/screens/ServerRedirect/serverRedirectUtils';
import { COLORS } from '../constants/colors';
import { SERVER_ERROR_CODES } from '../constants/constants';
import { ServerRedirectNavigationProp } from '../typesAndInterfaces/componentProps';
import { Answer, DIFFICULTY, GAME_SUBTYPE, GAME_TYPE, QuestionShort, ServerError } from '../typesAndInterfaces/typesAndInterfaces';
import { geographyLocationReadable } from '../components/screens/Game/geographyControls/geographyConstants';

type ValidateSubtypeResult<Subtype extends GAME_SUBTYPE> =
    Subtype extends GAME_SUBTYPE.number ? number :
    Subtype extends GAME_SUBTYPE.price ? { dollar: number, cents: number } :
    { dd: number, mm: number, yyyy: number };

export const utils = {
    getUrlParams: (url: string) => {
        try {
            let urlObj = new URL(url);
            return Object.fromEntries(urlObj.searchParams.entries());
        } catch (error) {
            console.error('Error: Failed to extract url params', error);
            return {};
        }
    },
    openExternalWindow: (url: string, name?: string, width?: number, height?: number) => {
        if (isMobileApp) {
            return;
        }

        const w = MB_customWindowForWeb.outerWidth - (width ?? 600);
        const h = MB_customWindowForWeb.outerHeight - (height ?? 750);
        const left = Math.round(MB_customWindowForWeb.screenX + w / 2);
        const top = Math.round(MB_customWindowForWeb.screenY + h / 2.5);

        MB_customWindowForWeb.open(
            url,
            name ?? 'LogIn',
            'width=' + (width ?? 600) + ',height=' + (height ?? 750) + ',left=' + left + ',top=' + top + ',toolbar=0,scrollbars=0,status=0,resizable=0,location=0,menuBar=0',
        );
    },
    getDeepLink: (path = '') => {
        return envs.MOBILE_DEEP_LINK_FOR_SERVER_REDIRECT + path;
    },
    openExternalAuthWindowForMobile: async (urlToOpen: string, navigation: ServerRedirectNavigationProp) => {
        try {
            const redirectUrl = utils.getDeepLink();
            let response = await WebBrowser.openAuthSessionAsync(urlToOpen, redirectUrl);
            if (response.type === 'success') {
                const params = utils.getUrlParams(response.url);
                serverRedirectUtils.handleServerRedirect({ errorParamBase64: params.error, successParamsBase64: params.success, navigation });
            } else {
                if (response.type === 'dismiss') {
                    return;
                }
                throw new Error(`Failed to find url in auth session.(${urlToOpen})`);
            }
        } catch (err) {
            console.log(`Error in handling mobile auth session (${urlToOpen}): - `, err);
        }
    },
    /**
     *
     * @param version string version 1
     * @param version2 string version 2
     * @returns -1 if version 1 < version 2, 0 if version 1 === version 2, 1 if version 1 > version 2
     */
    compareVersion: (version: string, version2: string) => {
        return version.localeCompare(version2, undefined, { numeric: true, sensitivity: 'base' });
    },
    createErrorObject: (message: string, errorCode: SERVER_ERROR_CODES | string): ServerError => {
        return {
            message,
            errorCode,
            status: 'error',
        };
    },

    combineComponents: (components: FC[]): FC => {
        function reducerFunction(AccumulatedComponents: React.FC<{}>, CurrentComponent: React.FC<{}>) {
            return ({ children }: ComponentProps<FC>): JSX.Element => {
                return (
                    <AccumulatedComponents>
                        <CurrentComponent>{children}</CurrentComponent>
                    </AccumulatedComponents>
                );
            };
        }

        return components.reduce(reducerFunction, ({ children }) => <>{children}</>);
    },

    showLogOutPopUp: (onSignOut?: () => void, onPopUpClosed?: () => void) => {
        mbShowPopUp({
            title: 'Are you sure you want to log out ?',
            buttonText: 'Yes',
            buttonAction: () => {
                // TODO: This is kind of ugly. We needed to do this because of some issues with iOS pop-ups and also on Android it was trying to perform an action on a component that was unmounted.
                mbHidePopUp();
                setTimeout(() => onPopUpClosed?.(), 0);
                setTimeout(() => onSignOut?.(), 400);
            },
            secondaryButtonText: 'No',
        });
    },

    getDifficultyTextColor: (difficulty: DIFFICULTY | undefined): string => {
        switch (difficulty) {
            case DIFFICULTY.beginner:
                return COLORS.beginnerBackgroundColor;
            case DIFFICULTY.beginnerPlus:
                return COLORS.beginnerTextColor;
            case DIFFICULTY.intermediate:
                return COLORS.intermediateBackgroundColor;
            case DIFFICULTY.intermediatePlus:
                return COLORS.intermediateTextColor;
            case DIFFICULTY.advanced:
                return COLORS.advancedBackgroundColor;
            case DIFFICULTY.advancedPlus:
                return COLORS.advancedTextColor;
            default:
                return COLORS.intermediateBackgroundColor;
        }
    },

    getDifficultyBackgroundColor: (difficulty: DIFFICULTY | undefined): string => {
        switch (difficulty) {
            case DIFFICULTY.beginner:
            case DIFFICULTY.beginnerPlus:
                return COLORS.beginnerBackgroundColor;
            case DIFFICULTY.intermediate:
            case DIFFICULTY.intermediatePlus:
                return COLORS.intermediateBackgroundColor;
            case DIFFICULTY.advanced:
            case DIFFICULTY.advancedPlus:
                return COLORS.advancedBackgroundColor;
            default:
                return COLORS.intermediateBackgroundColor;
        }
    },

    difficultyToText(difficulty: DIFFICULTY | undefined): string | undefined {

        if (!difficulty) {
            return undefined;
        }

        switch (difficulty) {
            case DIFFICULTY.beginner:
                return 'Beginner';
            case DIFFICULTY.beginnerPlus:
                return 'Beginner +';
            case DIFFICULTY.intermediate:
                return 'Intermediate';
            case DIFFICULTY.intermediatePlus:
                return 'Intermediate +';
            case DIFFICULTY.advanced:
                return 'Advanced';
            case DIFFICULTY.advancedPlus:
                return 'Advanced +';
            default:
                return '';
        }
    },
    areAnswersEqual: (answers: Partial<Omit<Answer, '_id'>>[], otherAnswers: Partial<Omit<Answer, '_id'>>[]) => {
        // check the size
        if (answers.length !== otherAnswers.length) {
            return false;
        }

        for (let i = 0; i < Math.max(answers.length, otherAnswers.length); i++) {
            const answer = answers[i];
            const otherAnswer = otherAnswers[i];
            // check if the elements exist
            if ((answer && !otherAnswer) || (!answer && otherAnswer)) {
                return false;
            }
            // check if the name is the same
            else if (answer.name !== otherAnswer.name) {
                return false;
            }
            // chek if the image is the same
            else if (answer.image?._id !== otherAnswer.image?._id) {
                return false;
            }
            // check if isCorrect is the same
            else if (answer.isCorrect !== otherAnswer.isCorrect) {
                return false;
            }
        }
        return true;
    },

    gameFeedbackToastParams: (isForCorrect: boolean): MB_ToastShowParams => {
        return {
            type: isForCorrect ? 'correctAnswer' : 'incorrectAnswer',
            position: 'top',
        };
    },

    validateSubtypeValue: <Subtype extends GAME_SUBTYPE>(subType: Subtype, value: string): ValidateSubtypeResult<Subtype> | undefined => {
        switch (subType) {
            case GAME_SUBTYPE.number:
                if (value.match(/^\d+$/)) {
                    return Number(value) as ValidateSubtypeResult<Subtype>;
                }
                break;
            case GAME_SUBTYPE.price:
                if (value.match(/(^\d+\.\d+$)/)) {
                    const [dollar, cents] = value.split('.').map(Number);
                    return { dollar, cents: cents } as ValidateSubtypeResult<Subtype>;
                }
                break;
            case GAME_SUBTYPE.date:
                if (value.match(/^\d{1,2}\/\d{1,2}\/\d{4}$/)) {
                    const [dd, mm, yyyy] = value.split('/').map(Number);
                    if (yyyy < 1000 || yyyy > 3000 || mm === 0 || mm > 12 || dd === 0 || dd > 31) {
                        return undefined;
                    }
                    const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
                    if (yyyy % 400 === 0 || (yyyy % 100 !== 0 && yyyy % 4 === 0)) {
                        monthLength[1] = 29;
                    }
                    if (dd > 0 && dd <= monthLength[mm - 1]) {
                        return { dd, mm, yyyy } as ValidateSubtypeResult<Subtype>;
                    }
                }
                break;
            case GAME_SUBTYPE.year:
                if (value.match(/^\d\d\d\d$/)) {
                    return Number(value) as ValidateSubtypeResult<Subtype>;
                }
        }
    },

    gameTypeToString: (gameType: GAME_TYPE): string => {
        switch (gameType) {
            case GAME_TYPE.IntroGame:
                return 'ASL Match (3 x 3)';
            case GAME_TYPE.MatchingItemsGame:
                return 'ASL Match: Spin Off';
            case GAME_TYPE.BodyParts:
                return 'Body parts';
            case GAME_TYPE.Geography:
                return 'Geography';
            case GAME_TYPE.Extras:
                return 'Extras';
            default:
                return '';
        }
    },

    getAnswer: (question: QuestionShort): string | number => {
        switch (question.gameType) {
            case GAME_TYPE.BodyParts:
                return question.bodyPart ?? 'N/A';
            case GAME_TYPE.Geography:
                return geographyLocationReadable[question.geographyLocation];
            default:
                return question.numberOfAnswers;
        }
    },

    hasMultiAnswers: <T extends { gameType?: GAME_TYPE } | undefined>(question: T): question is T & { gameType: GAME_TYPE.IntroGame | GAME_TYPE.MatchingItemsGame | GAME_TYPE.BodyParts } => question?.gameType !== GAME_TYPE.Geography,

    isValidURL: (url: string) => {
        try {
            const newUrl = new URL(url);
            return newUrl.protocol === 'http:' || newUrl.protocol === 'https:';
        } catch (err) {
            return false;
        }
    },
    encodeParam: (value: any) => value !== undefined ? Buffer.from(JSON.stringify(value)).toString('base64') : 'n/a',
    decodeParams: (data: any) => {
        Object.keys(data ?? {}).forEach(key => {
            if (typeof data[key] === 'object') {
                utils.decodeParams(data[key]);
            } else if (typeof data[key] === 'string') {
                if (data[key] === 'n/a') {
                    delete data[key];
                } else {
                    try { data[key] = JSON.parse(Buffer.from(data[key], 'base64').toString('ascii')); } catch { }
                }
            }
        });
        return data;
    },
};
