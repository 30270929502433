import React, { useCallback, useEffect, useState } from 'react';
import { ImageBackground, StyleSheet, View } from 'react-native';
import { Text } from 'react-native-animatable';
import { MB_Modal } from '@mightybyte/rnw.components.modal';
import { mbApplyTransparency, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { MB_EventEmmiter, MB_EventObject, MB_EVENT_EMMITER_EVENT_TYPE } from '@mightybyte/rnw.utils.event-emmiter';
import { mbHidePopUp, mbShowPopUp } from '@mightybyte/rnw.components.pop-up';
import { envs } from '../../../../env';
import MB_MobilePurchase, { MB_MOBILE_PURCHASE_EVENT_TYPE } from '../../../mightyByteLibraries/MB_MobilePurchase';
import { textStyles } from '../../../constants/textStyles';
import { COLORS } from '../../../constants/colors';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { MB_Button } from '@mightybyte/rnw.components.button';
import { imageUtils } from '../../../utils/imageUtils';
import { WarningIcon } from '../../../resources/svgComponents/WarningIcon';

const PaymentPageEventResponder = ({ onDismiss }: { onDismiss: (shouldNavigateBack: boolean) => void }) => {
    const [modalData, setModalData] = useState<{ isVisible: boolean, isForPendingPayment?: boolean }>({ isVisible: false });

    const mobilePurcahseEventListener = useCallback((event: MB_EventObject) => {
        const eventType = event?.data?.type;
        if (event?.origin !== envs.WEBSITE_BASE_URL || !MB_MobilePurchase.eventIsFromMobilePurchase(eventType)) {
            // The incoming event does not belong to this checkout page so we just ignore the event
            return;
        }

        if (modalData.isVisible) {
            // We might be already showing checkout success pop-up when we receive one of these events.
            // We don`t need to show anything anymore so we just return
            return;
        }

        switch (event.data.type) {
            case MB_MOBILE_PURCHASE_EVENT_TYPE.MOBILE_PURCHASE_CANCELLED:
                onDismiss(false);
                break;
            case MB_MOBILE_PURCHASE_EVENT_TYPE.MOBILE_PURCHASE_UPDATE_ERROR:
                console.error('MOBILE_PURCHASE_UPDATE_ERROR event receeived', JSON.stringify(event.data));
                mbShowPopUp({
                    title: 'Error',
                    message: 'Something went wrong, please try again.' + (envs.FLAVOR === 'dev' ? `\n${JSON.stringify(event.data)}` : ''),
                    buttonAction: () => {
                        mbHidePopUp();
                        onDismiss(false);
                    },
                });
                break;
            case MB_MOBILE_PURCHASE_EVENT_TYPE.MOBILE_PURCHASE_UPDATE_PENDING:
            case MB_MOBILE_PURCHASE_EVENT_TYPE.MOBILE_PURCHASE_UPDATE_SUCCESS:
                setModalData({
                    isVisible: true,
                    isForPendingPayment: event.data.type === MB_MOBILE_PURCHASE_EVENT_TYPE.MOBILE_PURCHASE_UPDATE_PENDING,
                });
                break;
        }
    }, [modalData.isVisible, onDismiss]);

    /**
    * Handles the lifecycle for in app purchase event listener.
    */
    useEffect(() => {
        const eventSub = MB_EventEmmiter.addListener(MB_EVENT_EMMITER_EVENT_TYPE.message, mobilePurcahseEventListener);

        return () => {
            eventSub?.remove();
        };
    }, [mobilePurcahseEventListener]);

    return (
        <MB_Modal
            isVisible={modalData.isVisible}
            hideCloseButton={true}
            simplified={true}
        >
            <View style={styles.checkmarkContainer}>
                {modalData.isForPendingPayment ?
                    <WarningIcon />
                    :
                    <MaterialCommunityIcons name="check" color={COLORS.white} size={48} />
                }
            </View>

            <View style={styles.modalContainer}>
                <ImageBackground
                    source={imageUtils.images.premiumBackground}
                    style={styles.imageBackground} />
                <Text style={styles.titleText}>{modalData.isForPendingPayment ? 'Payment Pending' : 'Welcome to premium!'}</Text>
                <Text style={styles.detailText}>{modalData.isForPendingPayment ? 'Payment is still being processed.' : 'You’ve unlocked premium games\nand game rooms.'}</Text>
                <MB_Button
                    title="Continue Playing"
                    style={styles.doneButton}
                    onPress={() => {
                        setModalData({ isVisible: false });
                        onDismiss(true);
                    }} />
            </View>
        </MB_Modal>
    );
};

export { PaymentPageEventResponder };

const styles = StyleSheet.create({
    checkmarkContainer: {
        aspectRatio: 1,
        marginBottom: -50,
        alignSelf: 'center',
        zIndex: 1,
        backgroundColor: COLORS.buttonPurple,
        borderRadius: 300,
        padding: 18,
        borderWidth: 24,
        borderColor: mbApplyTransparency(COLORS.buttonPurple, 0.5),
        alignItems: 'center',
    },
    modalContainer: {
        padding: 20,
        borderRadius: 16,
        paddingTop: 65,
        overflow: 'hidden',
    },
    imageBackground: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        borderRadius: 16,
    },
    titleText: mbTextStyles([
        textStyles.largeText,
    ]),
    detailText: mbTextStyles([
        textStyles.smallText, {
            marginTop: 24,
        },
    ]),
    doneButton: {
        marginVertical: 24,
        backgroundColor: COLORS.buttonPurple,
        borderRadius: 10,
        alignSelf: 'stretch',
    },
});
