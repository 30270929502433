import React, { createContext, ReactNode, useContext, useState } from 'react';

interface IUtilContext {
    dismissedPremiumBanner: boolean,
    setDismissedPremiumBanner: (dismissedPremiumBanner: boolean) => void,
}

const UtilContext = createContext<IUtilContext | undefined>(undefined);

const UtilContextProvider = ({ children }: { children?: ReactNode }) => {
    const [dismissedPremiumBanner, setDismissedPremiumBanner] = useState(false);

    const value = {
        dismissedPremiumBanner,
        setDismissedPremiumBanner,
    };

    return (
        <UtilContext.Provider value={value}>
            {children}
        </UtilContext.Provider>
    );
};

function useUtilContext() {
    const context = useContext(UtilContext);

    if (context === undefined) {
        throw new Error('useUtilContext must be used within a UtilContextProvider');
    }

    return context;
}

export { useUtilContext, UtilContext, UtilContextProvider };
