import { useEffect, useRef } from 'react';
import { Dimensions, useWindowDimensions } from 'react-native';
import { BackHandler } from 'react-native';
import { PAYMENT_STATUS, User } from '../typesAndInterfaces/typesAndInterfaces';
const utilHooks = {
    useWidthChange: (isActive: boolean, callBack: () => void) => {
        const dims = useWindowDimensions();
        const windowWidth = useRef(dims.width);

        // We need to set the width when window gets activated, otherwise if you change the window width and
        // then mount this, callback will get called.
        useEffect(() => {
            if (isActive) {
                windowWidth.current = Dimensions.get('window').width;
            }
        }, [isActive]);

        useEffect(() => {
            if (!isActive) {
                return;
            }

            if (dims.width !== windowWidth.current) {
                callBack();
                windowWidth.current = dims.width;
            }
        }, [callBack, dims.width, isActive]);
    },

    useAndroidBackButton: (backButtonAction: () => void) => {
        useEffect(() => {
            const handleBackButton = () => {
                backButtonAction();
                return true;
            };
            BackHandler.addEventListener('hardwareBackPress', handleBackButton);
            return () => BackHandler.removeEventListener('hardwareBackPress', handleBackButton);
        }, [backButtonAction]);
    },

    useGetAccess: (user?: User) => {
        return {
            canAccessRooms: user?.paymentStatus === PAYMENT_STATUS.paid || user?.manualPremium === true,
            canAccessGames: user?.paymentStatus === PAYMENT_STATUS.paid || user?.manualPremium === true,
        };
    },
};

export { utilHooks };
