import { z } from 'zod';
import { SERVER_ERROR_CODES } from '../constants/constants';
import { Category, ChangeCategoryPaymentResponse, CreateQuestionRquest, EditQuestionRequest, FOLDER_TYPE, Folder, GAME_TYPE, PaginationPage, Question, ServerError, User } from '../typesAndInterfaces/typesAndInterfaces';
import { CategoryObj, CategoryObjPagination, ChangeCategoryPaymentObj, FolderObj, QuestionObj, UserObjPagination } from '../utils/zod/zodObjects';
import { DIFFICULTY } from './../typesAndInterfaces/typesAndInterfaces';
import { axiosCaller } from './customAxios';

const adminApiCalls = {
    getUsers: async (page: number, totalItemsPerPage: number): Promise<PaginationPage<User>> => {
        return await axiosCaller({
            path: '/api/admin/getUsers',
            method: 'get',
            responseValidatorObj: UserObjPagination,
            responseFormatValidatorMessage: 'Data mismatch for get users data response',
            passToken: true,
            params: { page, totalItemsPerPage },
        });
    },
    deleteUser: async (userId: string): Promise<void> => {
        return await axiosCaller({
            path: '/api/admin/deleteUser',
            method: 'post',
            passToken: true,
            data: { userId },
        });
    },
    getCategories: async (page: number, totalItemsPerPage: number, gameType: GAME_TYPE): Promise<PaginationPage<Category>> => {
        return await axiosCaller({
            path: '/api/categories/getCategories',
            method: 'get',
            responseValidatorObj: CategoryObjPagination,
            responseFormatValidatorMessage: 'Data mismatch for get categories data response',
            passToken: true,
            params: { page, totalItemsPerPage, gameType },
        });
    },
    createCategory: async (name: string, gameType: GAME_TYPE, difficulty: DIFFICULTY[], tutorialURL?: string, image?: string): Promise<Category> => {
        return await axiosCaller({
            path: '/api/admin/createCategory',
            method: 'post',
            responseValidatorObj: CategoryObj,
            responseFormatValidatorMessage: 'Data mismatch for create category data response',
            passToken: true,
            responseDataKey: 'category',
            data: { name, gameType, difficulty, tutorialURL, image },
        });
    },
    getCategory: async (categoryId: string | undefined): Promise<Category> => {
        if (!categoryId) {
            const error: ServerError = {
                message: 'categoryId id was undefined',
                errorCode: SERVER_ERROR_CODES.GENERAL_ERROR,
                status: 'error',
            };

            throw error;
        }

        return await axiosCaller({
            path: '/api/categories/getCategory',
            method: 'get',
            responseValidatorObj: CategoryObj,
            responseFormatValidatorMessage: 'Data mismatch for get category data response',
            passToken: true,
            responseDataKey: 'category',
            params: { categoryId },
        });
    },
    toggleCategory: async (categoryId: string, isEnabled: boolean): Promise<Category> => {
        return await axiosCaller({
            path: '/api/admin/toggleCategory',
            method: 'post',
            responseValidatorObj: CategoryObj,
            responseFormatValidatorMessage: 'Data mismatch for toggle category data response',
            passToken: true,
            responseDataKey: 'category',
            data: { categoryId, isEnabled },
        });
    },
    editCategory: async (categoryId?: string, name?: string, difficulty?: DIFFICULTY[], tutorialURL?: string, image?: string, extras?: string[]): Promise<Category> => {
        return await axiosCaller({
            path: '/api/admin/editCategory',
            method: 'post',
            responseValidatorObj: CategoryObj,
            responseFormatValidatorMessage: 'Data mismatch for edit category data response',
            passToken: true,
            responseDataKey: 'category',
            data: { categoryId, name, difficulty, tutorialURL, extras, image },
        });
    },
    deleteCategory: async (categoryId: string): Promise<string> => {
        return await axiosCaller({
            path: '/api/admin/deleteCategory',
            method: 'post',
            responseValidatorObj: z.string(),
            responseFormatValidatorMessage: 'Data mismatch for delete category data response',
            responseDataKey: '_id',
            passToken: true,
            data: { categoryId },
        });
    },
    reorderCategories: async (categoriesOrder: { categoryId: string, order: number }[]): Promise<void> => {
        return await axiosCaller({
            path: '/api/admin/reorderCategories',
            method: 'post',
            passToken: true,
            data: { categoriesOrder },
        });
    },
    createQuestion: async (createQuestionRequest: CreateQuestionRquest): Promise<Question> => {
        return await axiosCaller({
            path: '/api/admin/createQuestion',
            method: 'post',
            responseValidatorObj: QuestionObj,
            responseFormatValidatorMessage: 'Data mismatch for create question data response',
            passToken: true,
            responseDataKey: 'question',
            data: createQuestionRequest,
        });
    },

    toggleQuestion: async (questionId: string, isEnabled: boolean): Promise<Question> => {
        return await axiosCaller({
            path: '/api/admin/toggleQuestion',
            method: 'post',
            responseValidatorObj: QuestionObj,
            responseFormatValidatorMessage: 'Data mismatch for toggle question data response',
            passToken: true,
            responseDataKey: 'question',
            data: { questionId, isEnabled },
        });
    },
    editQuestion: async (editQuestionRequest: EditQuestionRequest): Promise<Question> => {
        return await axiosCaller({
            path: '/api/admin/editQuestion',
            method: 'post',
            responseValidatorObj: QuestionObj,
            responseFormatValidatorMessage: 'Data mismatch for edit question data response',
            passToken: true,
            responseDataKey: 'question',
            data: editQuestionRequest,
        });
    },
    deleteQuestion: async (questionId: string): Promise<string> => {
        return await axiosCaller({
            path: '/api/admin/deleteQuestion',
            method: 'post',
            responseValidatorObj: z.string(),
            responseFormatValidatorMessage: 'Data mismatch for delete question data response',
            responseDataKey: '_id',
            passToken: true,
            data: { questionId },
        });
    },
    toggleCategoryPaymentState: async (categoryId: string, isFree: boolean): Promise<ChangeCategoryPaymentResponse> => {
        return await axiosCaller({
            path: '/api/admin/changeCategoryPaymentState',
            method: 'post',
            responseValidatorObj: ChangeCategoryPaymentObj,
            responseFormatValidatorMessage: 'Data mismatch for change category payment state data response',
            passToken: true,
            data: { categoryId, isFree },
        });
    },
    createFolder: async (name: string, type: FOLDER_TYPE): Promise<Folder> => {
        return await axiosCaller({
            path: '/api/admin/createFolder',
            method: 'post',
            responseValidatorObj: FolderObj,
            responseFormatValidatorMessage: 'Data mismatch for create folder data response',
            responseDataKey: 'folder',
            passToken: true,
            data: { name, type },
        });
    },
    getFolders: async (type: FOLDER_TYPE): Promise<Folder[]> => {
        return await axiosCaller({
            path: '/api/admin/getFolders',
            method: 'get',
            responseValidatorObj: z.array(FolderObj),
            responseFormatValidatorMessage: 'Data mismatch for get folders data response',
            responseDataKey: 'folders',
            passToken: true,
            params: { type },
        });
    },
    manualPremiumUser: async (userId: string, enabled: boolean): Promise<void> => {
        return await axiosCaller({
            path: '/api/admin/manualPremiumUser',
            method: 'post',
            passToken: true,
            data: { userId, enabled },
        });
    },
};

export { adminApiCalls };
