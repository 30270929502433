import React from 'react';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { ImageVideoPool } from '../../components/screens/Admin/ImageVideoPool/ImageVideoPool';
import { AdminNavigatorDrawer } from './AdminNavigatorDrawer';
import { Users } from '../../components/screens/Admin/Users';
import { COLORS } from '../../constants/colors';
import { StyleSheet } from 'react-native';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../constants/textStyles';
import { UsersSVG } from '../../resources/svgComponents/UsersSVG';
import AntDesign from 'react-native-vector-icons/AntDesign';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import { GamesCategoriesNavigatorStack } from './GamesCategories/GamesCategoriesNavigatorStack';
import { Folder, GAME_TYPE, RESOURCE_POOL_TYPE } from '../../typesAndInterfaces/typesAndInterfaces';
import { Ok } from '../../resources/svgComponents/Ok';
import { PuzzlePiece } from '../../resources/svgComponents/PuzzlePiece';
import { ImagePoolIcon } from '../../resources/svgComponents/ImagePoolIcon';
import { BodyIcon } from '../../resources/svgComponents/BodyIcon';
import { utils } from '../../utils/utils';
import { ExtrasIcon } from '../../resources/svgComponents/ExtrasIcon';

export type AdminNavigatorParamList = {
    Users: undefined,
    IntroToASL: { gameType: GAME_TYPE.IntroGame, page?: number },
    PriceMatch: { gameType: GAME_TYPE.MatchingItemsGame, page?: number },
    BodyParts: { gameType: GAME_TYPE.BodyParts, page?: number },
    Geography: { gameType: GAME_TYPE.Geography, page?: number },
    Extras: { gameType: GAME_TYPE.Extras, page?: number },
    ImagePool: { resourcePoolType: RESOURCE_POOL_TYPE.Image, folder?: Folder },
    VideoPool: { resourcePoolType: RESOURCE_POOL_TYPE.Video, folder?: Folder },
}

const Drawer = createDrawerNavigator<AdminNavigatorParamList>();

function GetDrawerIcon(routeName: keyof AdminNavigatorParamList) {
    switch (routeName) {
        case 'Users':
            return <UsersSVG />;
        case 'IntroToASL':
            return <Ok />;
        case 'PriceMatch':
            return <PuzzlePiece />;
        case 'BodyParts':
            return <BodyIcon />;
        case 'Geography':
            return <FontAwesome name="globe" color={COLORS.white} size={25} />;
        case 'Extras':
            return <ExtrasIcon />;
        case 'VideoPool':
            return <AntDesign name="videocamera" color={COLORS.white} size={25} />;
        case 'ImagePool':
            return <ImagePoolIcon />;
    }
}

const AdminNavigator = () => {
    return (
        <Drawer.Navigator
            screenOptions={{
                headerShown: false,
                drawerType: 'permanent',
                drawerActiveBackgroundColor: COLORS.backgroundDarkPurple,
                drawerLabelStyle: styles.drawerLabelStyle,
                drawerItemStyle: styles.drawerItemStyle,
            }}
            drawerContent={AdminNavigatorDrawer}
        >
            <Drawer.Screen name="Users" component={Users} options={{ drawerIcon: () => GetDrawerIcon('Users') }} />
            <Drawer.Screen name="IntroToASL" component={GamesCategoriesNavigatorStack} options={{ drawerLabel: utils.gameTypeToString(GAME_TYPE.IntroGame), drawerIcon: () => GetDrawerIcon('IntroToASL') }} initialParams={{ gameType: GAME_TYPE.IntroGame }} />
            <Drawer.Screen name="PriceMatch" component={GamesCategoriesNavigatorStack} options={{ drawerLabel: utils.gameTypeToString(GAME_TYPE.MatchingItemsGame), drawerIcon: () => GetDrawerIcon('PriceMatch') }} initialParams={{ gameType: GAME_TYPE.MatchingItemsGame }} />
            <Drawer.Screen name="BodyParts" component={GamesCategoriesNavigatorStack} options={{ drawerLabel: utils.gameTypeToString(GAME_TYPE.BodyParts), drawerIcon: () => GetDrawerIcon('BodyParts') }} initialParams={{ gameType: GAME_TYPE.BodyParts }} />
            <Drawer.Screen name="Geography" component={GamesCategoriesNavigatorStack} options={{ drawerLabel: utils.gameTypeToString(GAME_TYPE.Geography), drawerIcon: () => GetDrawerIcon('Geography') }} initialParams={{ gameType: GAME_TYPE.Geography }} />
            <Drawer.Screen name="Extras" component={GamesCategoriesNavigatorStack} options={{ drawerLabel: utils.gameTypeToString(GAME_TYPE.Extras), drawerIcon: () => GetDrawerIcon('Extras') }} initialParams={{ gameType: GAME_TYPE.Extras }} />
            <Drawer.Screen name="ImagePool" component={ImageVideoPool} options={{ drawerLabel: 'Image Pool', drawerIcon: () => GetDrawerIcon('ImagePool') }} initialParams={{ resourcePoolType: RESOURCE_POOL_TYPE.Image }} />
            <Drawer.Screen name="VideoPool" component={ImageVideoPool} options={{ drawerLabel: 'Video Pool', drawerIcon: () => GetDrawerIcon('VideoPool') }} initialParams={{ resourcePoolType: RESOURCE_POOL_TYPE.Video }} />
        </Drawer.Navigator>
    );
};

export { AdminNavigator };


const styles = StyleSheet.create({
    drawerLabelStyle: mbTextStyles([
        textStyles.smallText, {
            fontWeight: '600',
            textAlign: 'left',
            padding: 1,
            minWidth: 150,
        },
    ]),
    drawerItemStyle: {
        borderRadius: 7,
        paddingHorizontal: 16,
    },
});
